const formatPhone = (tel) => {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, "$1****$2");
}

const downloadImage = (data, filename) => {
    var httpindex = data.indexOf('http')
    if (httpindex === 0) {
        const image = new Image()
            // 解决跨域 canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function() {
            const canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            const context = canvas.getContext('2d')
            context.drawImage(image, 0, 0, image.width, image.height)
            const dataURL = canvas.toDataURL('image/png')
                // 生成一个 a 标签
            const a = document.createElement('a')
                // 创建一个点击事件
            const event = new MouseEvent('click')
                // 将 a 的 download 属性设置为我们想要下载的图片的名称，若 name 不存在则使用'图片'作为默认名称
            a.download = filename || '图片'
                // 将生成的 URL 设置为 a.href 属性
            var blob = dataURLtoBlob(dataURL)
            a.href = URL.createObjectURL(blob)
                // 触发 a 的点击事件
            a.dispatchEvent(event)
        }
        image.src = data
    } else {
        // 生成一个 a 标签
        const a = document.createElement('a')
            // 创建一个点击事件
        const event = new MouseEvent('click')
            // 将 a 的 download 属性设置为我们想要下载的图片的名称，若 name 不存在则使用'图片'作为默认名称
        a.download = filename || '图片'
            // 将生成的 URL 设置为 a.href 属性
        a.href = data
            // 触发 a 的点击事件
        a.dispatchEvent(event)
    }
}
const dataURLtoBlob = (dataurl) => {
    // eslint-disable-next-line one-var
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {
        type: mime
    })
}
export {
    formatPhone,
    downloadImage
}